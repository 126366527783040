$enkla-primary: #3498db;
$enkla-secondary: $enkla-primary;
//$enkla-primary: #084ccf;
//$enkla-secondary: #a29bfe;
//$enkla-secondary: rgba(241,242,244,.6);
$enkla-dark: #20232a;
$enkla-medium: #5e605f;
$enkla-light: #f6f6f6;

$text: #212529;
$text-light: #6f7477;
$text-muted: #a7aeb8;
$lax: #f3756d;
$body-bg: $enkla-light !default;

$sidebar-primary: $enkla-dark;
$sidebar-secondary: #313333;
$sidebar-text: #babdc2;
$sidebar-text-active: #fff;

$blue: #38bdf8 !default;
$indigo: #6610f2 !default;
$purple: #6f42c1 !default;
$pink: #d63384 !default;

$red: #e74c3c !default;
$orange: #f9ca24 !default;
$yellow: #ffc107 !default;
$green: #4ade80 !default;
$teal: #20c997 !default;
$cyan: #0dcaf0 !default;

$white: #fff !default;
$black: #000 !default;

$primary: $enkla-primary !default;
$secondary: $enkla-secondary !default;
$success: $green !default;
$info: $cyan !default;
$warning: $yellow !default;
$danger: $red !default;
$light: $enkla-light !default;
$dark: $enkla-dark !default;

.login-wrapper {
  display: flex;
  flex-wrap: wrap;
  background: #fff;
  .login-logo {
    position: absolute;
    top: 15px;
    left: 15px;
    width: 200px;
  }
  .text-divider {
    margin: 2em 0;
    line-height: 0;
    text-align: center;
  }
  .text-divider span {
    background-color: #fff;
    padding: 1em;
    color: #a1a5b7;
  }
  .text-divider:before {
    content: ' ';
    display: block;
    border-top: 1px solid #e3e3e3;
    border-bottom: 1px solid #f7f7f7;
  }
  span.wave {
    text-align: center;
    font-size: 32px;

    animation-duration: 2.5s; /* Change to speed up or slow down */
    animation-iteration-count: infinite; /* Never stop waving :) */

    display: inline-block;
    &:hover {
      animation-name: wave-animation;
    }
  }
  .login-hero {
    position: relative;
    background: #f6f6f6;
    .login-hero-content {
      height: 100%;
      img {
        border-bottom-left-radius: 50%;
        border-bottom-right-radius: 50%;
      }
    }
  }
  .user-form {
    max-width: 600px;
    width: 80%;
    button {
      background: $enkla-secondary;
      &:hover,
      &:active {
        background-color: darken($enkla-secondary, 10%);
      }
      padding: 13px 10px;
      color: #fff;
      width: 100%;
    }
    input {
      width: 100%;
      appearance: none;
      border-radius: 4px;
      padding: 13px 10px;
      line-height: 1.5;
      color: #5e6278;
      background-color: #ffffff;
      border: 1px solid #e4e6ef;
      box-shadow: none !important;
    }
  }
}
@keyframes wave-animation {
  0% {
    transform: rotate(0deg);
  }
  10% {
    transform: rotate(14deg);
  }
  20% {
    transform: rotate(-8deg);
  }
  30% {
    transform: rotate(14deg);
  }
  40% {
    transform: rotate(-4deg);
  }
  50% {
    transform: rotate(10deg);
  }
  60% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

.divider {
  overflow: hidden;
  position: relative;
}
.divider::before {
  content: '';
  font-family: 'shape divider from ShapeDividers.com';
  position: absolute;
  bottom: -1px;
  left: -1px;
  right: -1px;
  top: -1px;
  z-index: 3;
  pointer-events: none;
  background-repeat: no-repeat;
  background-size: 85px 117%;
  background-position: 0% 50%;
  background-image: url('data:image/svg+xml;charset=utf8, <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2.17 35.28" preserveAspectRatio="none"><path d="M.5 35.28C1.04 32.2.07 26 .5 19.13.9 12.26 1.06 5.4.38 0H0v35.28z" fill="%23ffffff"/><path d="M1 35.28c.8-3.17-.38-7.3-.55-10.04-.17-2.76 1.47-9.25 1.3-12.68C1.58 9.14.91 7.92.88 5.52.86 3.12 1.76 0 1.76 0H0v35.28z" opacity=".5" fill="%23ffffff"/><path d="M1.85 35.28c-.83-2.57-.3-7.68-.42-11.8-.12-4.1-.61-6.85-.28-9.57.33-2.73 1.17-5.61 1-8.61-.19-3-.82-4.73-.84-5.3H.1v35.28z" opacity=".5" fill="%23ffffff"/></svg>');
}
.verification-text {
  margin-top: 2rem;
}

.component_sidebar::-webkit-scrollbar {
  display: none;
}
.component_sidebar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  @media (max-width: 450px) {
    display: none !important;
  }
  overflow-y: scroll;
  width: 260px;
  background: $sidebar-primary;
  border-radius: 15px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  margin: 15px;
  margin-right: 0px;
  max-height: calc(100vh - 30px);
  .sidebar-header {
    font-weight: 500;
    color: #fff;
    padding: 27px 30px;
    span {
      font-size: 18px !important;
    }
    i {
      color: #e74c3c;
    }
    .logo-wrapper {
      position: relative;
      padding-top: 30px;
      .logo {
        position: absolute;
        top: 0;
        left: 0;
        height: 30px;
        image-rendering: -moz-crisp-edges;
        image-rendering: -o-crisp-edges;
        image-rendering: -webkit-optimize-contrast;
        image-rendering: crisp-edges;
        -ms-interpolation-mode: nearest-neighbor;
      }
      > small {
        font-size: 12px;
        display: block;
      }
    }
  }
  .sidebar-items-list {
    padding-top: 15px;
    .sidebar-item-collapsable {
      .btn-toggle {
        background: transparent !important;
        position: relative;
        &:hover {
          background: $sidebar-secondary !important;
        }
        .fa-chevron-down,
        .fa-chevron-left {
          position: absolute;
          right: 10px;
        }
      }
    }
    .boarded-button {
      a {
        background: $enkla-secondary !important;
        color: #fff !important;
        margin-bottom: 20px;
        &:hover {
          transition: 0.2s;
          background: darken($enkla-secondary, 5%) !important;
        }
      }
    }
    .sidebar-item {
      padding: 0 23px;
      margin-bottom: 14px !important;

      .sidebar-single-item {
        color: $sidebar-text;
      }
      .btn-toggle {
        display: inline-flex;
        align-items: center;
        padding: 10px;
        background-color: transparent;
        border: 0;
        width: 100%;
        box-shadow: none !important;
        color: $sidebar-text;
        font-weight: 500;
        font-size: 14px;
        &:hover,
        &.active {
          color: $sidebar-text-active !important;
          background: $sidebar-secondary;
          transition: 0.3s;
        }
        i {
          margin-right: 7px;
          margin-left: 5px;
        }
        i:last-child {
          margin-left: 10px;
        }
      }
      .collapse {
        margin-left: 23px !important;
        margin-top: 6px;

        .sidebar-subitem {
          margin-bottom: 5px;
          font-size: 13px;
          .sidebar-link {
            display: flex;
            align-items: center;
            padding: 9px 13px;
            width: 100%;
            margin-top: 0.125rem;
            font-weight: 500;
            text-decoration: none;
            color: $sidebar-text;
            &:hover,
            &.active {
              background-color: $sidebar-secondary;
              color: $sidebar-text-active;
              transition: 0.3s;
            }
            i {
              margin-right: 7px;
              font-size: 12px;
            }
          }
        }
        .sidebar-subitem:last-child {
          margin-bottom: 0px;
        }
      }
    }
  }

  &.collapsed {
    width: 91px;
    .sidebar-header .text-logo {
      display: none;
    }
    .logo-wrapper small {
      display: none;
    }
    .logo {
      clip: rect(0px, 20px, 30px, 0px);
    }
    .sidebar-item {
      position: relative;
      &:hover {
        .collapse {
          display: block !important;
        }
      }
      .collapse {
        &.show {
          display: none;
        }
        &:hover {
          display: block !important;
        }

        position: absolute;
        top: -25px !important;
        left: 50px !important;
        z-index: 1002;
        width: 230px;
        border-bottom-right-radius: 5px;
        border-top-right-radius: 5px;
        border-left: none;
        background: $sidebar-primary;

        ul {
          padding: 15px;
        }
      }
    }
    .btn-toggle {
      z-index: 1001;
      justify-content: center;
      align-items: center;
      padding-top: 10px;
      padding-bottom: 10px;
      span {
        display: none;
      }
      i {
        margin: 0;
      }
    }
  }
}
.sidebar-menu-badge {
  margin-left: auto;
}
.mobile-display {
  position: absolute;
  display: block !important;
  z-index: 999;
  width: calc(100% - 30px);
  top: 75px;
  min-height: calc(100vh + 57px);
  border-radius: 15px !important;
  .sidebar-header {
    margin-bottom: 0px !important;
  }
}

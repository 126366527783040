.Toastify__toast--default {
}
.Toastify__toast--info {
}
.Toastify__toast--success {
  background: $green !important;
}
.Toastify__toast--warning {
  background: $orange !important;
}
.Toastify__toast--error {
  background: $red;
}
.Toastify__toast-container {
  margin-top: 48px;
}

.notengo-content {
  height: calc(100vh - 80px);
  display: flex;
  justify-content: center;
  align-items: center;
  .notengo-wrapper {
    display: contents;
    color: $text-light;
    font-size: 50px;
    i {
      margin-right: 10px;
    }
  }
}

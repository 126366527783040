.component_Dropdown {
  position: relative;
  .btn {
    i {
      margin-right: 3px;
    }
  }
  .dropdown-list {
    top: 40px;

		background: #fff !important;
    position: absolute;
    z-index: 999;
    border-radius: 3px;
    font-size: 13px;
    .dropdown-item {
      padding-top: 12px;
      padding-bottom: 12px;
      font-size: 13px;
      font-weight: 500;
      &:active {
        color: #212529;
      }
      i {
        margin-right: 3px;
      }
      &:hover {
        cursor: pointer;
      }
    }
    .dropdown-item-disabled {
      background-color: #ddd !important;
      &:hover {
        cursor: not-allowed;
      }
    }
  }
}

.fc-h-event {
	background-color: #f0f0f0;
	border: 1px solid #f0f0f0;
	border-radius: 5px;
	padding-left: 10px;
	margin: 5px;
	cursor: pointer;
	transition: all 0.2s;

	b {
		line-height: 19px;
	}

	&:hover {
		background-color: #e0e0e0;
		border: 1px solid #e0e0e0;
		color: #000;
	}

	.fc-event-main {
		> span {
			> p, > b {
				display: block;
				color: #000;
			}

			> p {
				margin-bottom: 0px;
			}
		}
	}
}

.calendar-type-icon {
	display: inline-flex;
	width: 25px;
	height: 25px;
	justify-content: center;
	align-items: center;
	line-height: 15.5px;

	border-radius: 50%;
	padding: 5px;
}

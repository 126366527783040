.component_FAQ {
  padding: 5px 0px;
  .faq-inner {
    border-bottom: 2px dashed #f1f1f1;
    .header {
      margin-bottom: 10px;
      .faq-toggle {
        font-size: 19px;
        margin-right: 3px;
        text-decoration: none;
        &:hover {
          i {
            color: #009ef7;
          }
          cursor: pointer;
        }
        span {
          font-weight: 700;
          font-size: 16px;
          margin-left: 8px;
          vertical-align: text-bottom;
        }
      }
      .icon-toggled {
        color: #009ef7;
      }
    }
    .faq-answer {
      padding: 5px 32px;
      font-weight: 600;
    }
    .faq-answer-toggled {
      max-height: 500px;
      transition: max-height 0.25s ease-in;
    }
  }
}
.component_FAQ:last-child {
  .faq-inner {
    border-bottom: none;
  }
}

.group-steps-container {
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 50px;
}

.group-step-active {
	color: $enkla-dark !important;
	span:first-child {
		color: #fff !important;
		background-color: $enkla-dark !important;;
	}
}
.group-step {
	font-weight: 500;
	display: flex;
	align-items: center;
	border-radius: 5px;
	cursor: pointer;
	position: relative; // For the line to be positioned relative to the step

	color: #818181;
	span:first-child {
		display: inline-block;
		background-color: #e1e1e1;
		margin-right: 10px;
		width: 37px;
		height: 37px;
		border-radius: 50%;
		display: flex;
		justify-content: center;
		align-items: center;
		font-weight: bold;
	}

	// Add line between steps except the last one
	&::after {
		content: '';
		width: 30px; // Adjust the line length as needed
		height: 2px;
		background-color: #ccc; // Line color
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		right: -40px; // Space between the line and the step
	}

	&:last-child::after {
		display: none; // Hide the line for the last item
	}

	&:hover {
		color: $enkla-dark;
		span:first-child {
			color: #fff !important;
			background-color: $enkla-dark !important;;
		}
	}
}
.group-step-completed {
	span:first-child {
background-color: $green;
		color: #fff;
	}
}
.taskgroup-info {
	margin-bottom: 10px;
	.taskgroup-desc{
		font-size: 15px;
		margin-top:50px;
		margin-bottom: 25px;
	}
	}
.taskk-wrapper {
	display: flex;
	justify-content: start;
	align-items: center;
	    border-bottom: 1px solid #f1f1f1;
    padding: 15px;
		transition: .2s;
		&:hover {
		cursor: pointer;
		background-color: #f1f1f1;
		transition: .2s;
	}
	div {
		flex-grow: 1;
		margin-left: 10px;
		small {
			color: #959595;
		}
	}
	i:last-child {
	margin-right: 10px;
	}
}


@import 'bootstrap/bootstrap.scss';
@import 'override/_form.scss';
@import 'override/_swal.scss';
@import 'override/_toastify.scss';
@import 'override/_modal.scss';
@import 'override/_datepicker.scss';
@import 'override/calendar.scss';

@import 'components.scss';
@import '../views/styles.scss';
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

.container {
  margin-bottom: 10px;

  @media (max-width: 1700px) {
    padding: 0px 5rem;
  }

  @media (max-width: 1100px) {
    padding: 0px 1.5rem;
  }
}

.link:hover {
  cursor: pointer;
}

.text {
  color: $text !important;
}
.text-light {
  color: $text-light !important;
}
.text-muted {
  color: $text-muted !important;
}
.text-white {
  color: #fff;
}
.btn-primary {
  color: #fff !important;
  i {
    color: #fff !important;
  }
}
.link {
  color: $primary;
  &:hover {
    transition: all 0.2s;
    color: darken($primary, 5%);
  }
}

.icon-link {
  background-color: transparent;
  border: none;
  color: $text-light;
  transition: 0.2s;

  &:hover {
    cursor: pointer;
    color: darken($text-light, 15);
    transition: 0.2s;
  }
}

.fc .fc-toolbar-title {
	text-transform: capitalize;
}
.bicflex {
  display: flex;
  justify-content: center;
  align-items: center;
}
.full-height {
  min-height: 100vh;
}

.pointer {
  cursor: pointer !important;
}

.no-padding {
  padding: 0 !important;
}

.text-right {
  text-align: right;
}

.mr-5 {
  margin-right: 5px;
}

.ml-5 {
  margin-left: 5px;
}

.mr-10 {
  margin-right: 10px;
}

.alert-danger {
  background: $red;
}

.alert-info {
  background: $blue;
}

.alert-success {
  background: $green;
}

.alert {
  color: #fff;
  border: none;
  opacity: 0.5;

  p {
    font-weight: 500;
    font-size: 13px;
    margin-bottom: 0px;
  }
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-15 {
  margin-bottom: 15px;
}

.pull-right {
  float: right;
}

.btn-link {
  color: $text-muted;
  transition: all 0.1s;

  &:hover {
    cursor: pointer;
    color: $text;
  }
}

.rdrCalendarWrapper {
  min-width: 340px;

  .rdrMonthAndYearWrapper,
  .rdrMonths {
    display: none;
  }

  &:hover {
    .rdrMonthAndYearWrapper,
    .rdrMonths {
      display: block !important;
    }
  }
}

.select-clear {
  cursor: pointer;
  position: absolute;
  right: 50px;
  bottom: 15px;
  font-size: 12px;
  color: $text;
}

.container-absolute-center {
  height: calc(100vh - 111px);
  display: flex;
  justify-content: center;
  align-items: center;
}

.cardlabel {
  font-weight: 600;
  font-size: 13px;
}
.overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 99999999;
}
.input-required {
  color: $red;
  font-weight: bold;
}
.color-success {
	color: $green !important;
}
.color-disabled {
	color: #8c8c8c
}

.no-margin {
	margin: 0 !important;
}
mark {
	padding: 0 !important;
background-color: #bff7f7 !important;
}

.ReactModal__Overlay {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.7) !important;
  z-index: 1050;
}
.ReactModal__Content {
  width: 600px;
  height: auto;
  border: none !important;
  position: relative !important;
  outline: none !important;
  padding: 0 !important;
	.card {
	background: #fff;
		border: none;
	}
}

.component_ViewHeader {
  padding-top: 10px;
  padding-bottom: 30px;
  position: relative;
  .viewheader-return-wrapper {
    padding-bottom: 17px;
    .viewheader-return {
      text-decoration: none;
      color: $text;
      font-weight: 500;
      transition: 0.3s;
      font-size: 13px;
      &:hover {
        color: darken($text, 10%);
        transition: 0.3s;
      }
    }
  }
  .viewheader-header-container {
    .viewheader-left {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      i {
        font-size: 52px;
        margin-right: 7px;
      }
      .viewheader-text-wrapper {
        display: grid;
        .viewheader-title {
          font-weight: 500;
          font-size: 30px;
          color: $text;
        }
        .viewheader-subtitle {
          font-weight: 600;
          color: $text-light;
          font-size: 15px;
        }
      }
    }
    .viewheader-right {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      .badge {
        font-size: 16px;
        margin-left: 5px;
        padding: 6px 16px;
      }
    }
  }
  .viewheader-button-container {
    margin-top: 25px;
    .col {
      ul {
        list-style-type: none;
        margin: 0;
        padding: 0;

        .viewheader-listitem-right {
          float: right;
          button {
            margin-left: 10px;
            margin-right: 0;
          }
        }
        li {
          float: left;
          button {
            margin-right: 10px;
          }
        }
      }
    }
  }
}

@keyframes spicy {
  from {
    background-color: $green;
  }
  to {
    background-color: white;
  }
}
.component_Table {
  .sortable-table-head {
    &:hover {
      cursor: pointer;
    }
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;
    span {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .sort-icon {
        margin-left: 10px;
      }
    }
  }
  .fillas-fucking-neon {
    background: $green;
    animation: spicy 5s forwards;
  }
  overflow-x: auto;
  border-radius: 0.75rem;
  margin: -16px;
  thead {
    color: $text;
    font-size: 14px;
    font-weight: 300;
    border-bottom: 1px solid #f1f1f1;
    th {
      font-weight: 600;
      background: transparent;
      border: none !important;
      margin-left: 2px;
      padding: 18px 30px;

      &:hover {
        cursor: default;
      }
    }
  }
  tbody {
    tr {
      border-bottom: 1px solid #f1f1f1;
      transition: 0.1s;
      &:hover {
        background: #f1f1f1;
        cursor: pointer;
        transition: 0.1s;
      }
      td {
        //vene hur man löser ellipsis lol
        color: $text;
        max-width: 15rem;
        width: 0px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 14px;
        font-weight: 500;
        padding: 15px 30px;
      }
    }
  }
}

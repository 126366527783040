.user-dropdown {
  min-width: 200px;
		background: #fff;
  .user-info {
    display: inline-grid;
    text-align: center;
    padding: 20px;
    width: 100%;
    span {
      margin: 0;
      font-size: 15px;
    }
    span:first-child {
      font-weight: 600;
    }
    span:last-child {
      font-size: 11px;
      color: $text-muted;
    }
  }
}
.notifications {
  .clear-notifications {
    background-color: $enkla-light;
    border-color: $enkla-light;
  }
  .fa-stack[data-count]:after {
    position: absolute;
    right: -5%;
    top: -18%;
    font-weight: bold;
    content: attr(data-count);
    font-size: 60%;
    padding: 0.6em;
    border-radius: 999px;
    line-height: 0.75em;
    color: white;
    background: rgba(255, 0, 0, 0.85);
    text-align: center;
    min-width: 2em;
    font-weight: bold;
  }
  .notification-unread {
    background: #f0f0f0;
  }
  .notification-item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 20px;
    max-width: 400px;
    white-space: inherit;
    width: 400px;
    position: relative;
    @media (max-width: 450px) {
      width: 100%;
    }
    .clear-notification {
      position: absolute;
      right: 0;
      top: 0;
    }
    p {
      margin: 5px 0px;
      max-height: 200px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    i {
      font-size: 25px;
    }
    .fa-times {
      font-size: 15px;
    }
    div {
      display: grid;
      padding: 0px 15px;
      span {
        padding: 0;
        text-decoration: none;
        font-weight: 600;
        color: $text;
      }
    }
  }
}
.pipey-dropdown {
	width: 600px;
}

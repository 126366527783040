.component_Pagination {
  margin-top: 3rem;
  .pagination {
    .page-item {
      .page-link {
        padding: 8px 14px !important;
        font-size: 12px;
        color: $enkla-primary !important;
        border: none !important;
        border-radius: 4px;
        margin: 0px 1px;
        font-weight: 600;
        background: none !important;
        &:hover {
          background: $enkla-primary !important;
          color: #fff !important;
        }
      }
    }
    .active {
      .page-link {
        color: #fff !important;
        background: $enkla-primary !important;
      }
    }
    .disabled {
      .page-link {
        color: $text-muted !important;
      }
    }
  }
}

.component_BeefyButton {
  position: relative;
  overflow: hidden;
  .beefy-button-primary {
    color: #fff !important;
    background: $blue !important;
    .icon-wrapper {
      background: darken($blue, 10) !important;
    }
    .hover-wrapper {
      background: darken($blue, 10) !important;
    }
  }
  .beefy-button-info {
    color: $text !important;
    background: #e6e6e6 !important;
    .icon-wrapper {
      background: darken(#e6e6e6, 5) !important;
    }
    .hover-wrapper {
      background: darken(#e6e6e6, 5) !important;
    }
  }
  .beefy-button-warning {
    background: $orange !important;
    color: darken($orange, 25) !important;
    .icon-wrapper {
      background: darken($orange, 10) !important;
    }
    .hover-wrapper {
      background: darken($orange, 10) !important;
      color: #fff !important;
    }
  }
  .beefy-button-danger {
    background: $red !important;
    color: darken($red, 35) !important;
    .icon-wrapper {
      background: darken($red, 10) !important;
    }
    .hover-wrapper {
      background: darken($red, 10) !important;
      color: #fff !important;
    }
  }
  .beefy-button-success {
    background: $green !important;
    color: darken($green, 33) !important;
    .icon-wrapper {
      background: darken($green, 10) !important;
    }
    .hover-wrapper {
      background: darken($green, 10) !important;
      color: #fff !important;
    }
  }
  .beefy-button {
    font-weight: 600;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    transition: 0.3s;
    .icon-wrapper {
      margin-right: 25px;
      padding: 25px 40px;
      border-top-left-radius: 3px;
      border-bottom-left-radius: 3px;
      i {
        //låg på sjukhus låt mig dra en absolute
        position: absolute;
        top: 17px;
        left: 30px;
        font-size: 17px;
      }
      .icon-default {
        transition: opacity 0.3s, transform 0.3s;
      }
      .icon-hover {
        transition: opacity 0.3s, transform 0.3s;
        transform: rotate(-180deg) scale(0.5);
        opacity: 0;
      }
    }
    .hover-wrapper {
      position: absolute;
      width: calc(100% - 80px);
      left: 80px;
      height: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      opacity: 0;
      transition: opacity 0.5s;
      span {
        padding-left: 25px;
      }
    }

    &:hover {
      cursor: pointer;
      .hover-wrapper {
        opacity: 1;
      }
      .text-default {
        display: none;
      }
      .icon-hover {
        transform: rotate(0deg) scale(1);
        opacity: 1;
      }
      .icon-default {
        transform: rotate(180deg) scale(0.5);
        opacity: 0;
      }
    }
  }
  .beefy-disabled {
    &:hover {
      cursor: default;
      .hover-wrapper {
        opacity: 0 !important;
      }
      .text-default {
        display: block;
      }
      .icon-hover {
        transform: none;
        opacity: 0;
      }
      .icon-default {
        transform: none;
        opacity: 1;
      }
    }
  }
}

@keyframes FadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-moz-keyframes FadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes FadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-o-keyframes FadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-ms-keyframes FadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

//
// Base styles
//

.card-image-header {
  display: flex;
  justify-content: center;
  align-items: center;
}
.card-buttons {
  display: flex;
  justify-content: center;
  align-items: baseline;
  button {
    font-size: 14px;
    width: 100%;
    height: 50px;
    font-weight: 600;
    border-radius: 0px;
    &:active, &:hover {
      outline: none !important;
    }
  }

  button:first-child {
    border-bottom-left-radius: 0.25rem !important;
  }
  button:last-child {
    border-bottom-right-radius: 0.25rem !important;
  }
}
.card-row {
  margin-bottom: 10px;
}
.card-data-tag {
  display: grid;
  background: #f1f1f1;
  margin: 15px;
  padding: 8px 12px;
  border-radius: 4px;
  label {
    font-weight: 600;
    font-size: 13px;
  }
  span {
    font-weight: 400;
    font-size: 15px;
  }
}
.card-description {
  margin-bottom: 7px;
  font-size: 13px !important;
}
.card {
  // box-shadow: 0 0.75rem 1.5rem rgba(18, 38, 63, 0.03);
  //box-shadow: 0 0.15rem 0.5rem rgba(33, 40, 50, 0.15);
  //box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%);
		//box-shadow: 5px 5px 0 rgba(7,77,207,.05),-5px -5px 0 rgba(7,77,207,.05);
	box-shadow: 5px 5px 0 rgba(7,77,207,.05);

  margin-bottom: 25px;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0; // See https://github.com/twbs/bootstrap/pull/22740#issuecomment-305868106
  height: $card-height;
  word-wrap: break-word;
  background-color: #ffffff4d;
  background-clip: border-box;
		border-radius: .25rem !important;
	border: 1px solid rgba(209,213,219,.9);

  @include border-radius($card-border-radius);
  @include box-shadow($card-box-shadow);

  > hr {
    margin-right: 0;
    margin-left: 0;
  }

  > .list-group {
    border-top: inherit;
    border-bottom: inherit;

    &:first-child {
      border-top-width: 0;
      @include border-top-radius($card-inner-border-radius);
    }

    &:last-child  {
      border-bottom-width: 0;
      @include border-bottom-radius($card-inner-border-radius);
    }
  }

  // Due to specificity of the above selector (`.card > .list-group`), we must
  // use a child selector here to prevent double borders.
  > .card-header + .list-group,
  > .list-group + .card-footer {
    border-top: 0;
  }
}

.card-body {
  // Enable `flex-grow: 1` for decks and groups so that card blocks take up
  // as much space as possible, ensuring footers are aligned to the bottom.
  flex: 1 1 auto;
  padding: $card-spacer-y $card-spacer-x;
  color: $card-color;
}

.card-title {
  margin-bottom: $card-title-spacer-y;
}

.card-subtitle {
  margin-top: -$card-title-spacer-y * .5;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link {
  &:hover {
    text-decoration: if($link-hover-decoration == underline, none, null);
  }

  + .card-link {
    margin-left: $card-spacer-x;
  }
}

//
// Optional textual caps
//

.card-header {
  padding: 0.75rem 0.75rem 0.15rem;
  margin-bottom: 0; // Removes the default margin-bottom of <hN>
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 15px;
  .card-header-buttons {
    justify-self: flex-end;
  }
  .card-header-text {
    span {
      color: $text;
    }
    i {
      display: inline-block;
      margin-right: 8px;
      font-size: 17px;
    }
  }


  &:first-child {
    @include border-radius($card-inner-border-radius $card-inner-border-radius 0 0);
  }
}

.card-footer {
  padding: 20px;
  color: $card-cap-color;
  background-color: $card-cap-bg;
  border-top: $card-border-width solid $card-border-color;

  &:last-child {
    @include border-radius(0 0 $card-inner-border-radius $card-inner-border-radius);
  }
}


//
// Header navs
//

.card-header-tabs {
  margin-right: -$card-cap-padding-x * .5;
  margin-bottom: -$card-cap-padding-y;
  margin-left: -$card-cap-padding-x * .5;
  border-bottom: 0;

  @if $nav-tabs-link-active-bg != $card-bg {
    .nav-link.active {
      background-color: $card-bg;
      border-bottom-color: $card-bg;
    }
  }
}

.card-header-pills {
  margin-right: -$card-cap-padding-x * .5;
  margin-left: -$card-cap-padding-x * .5;
}

// Card image
.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: $card-img-overlay-padding;
  @include border-radius($card-inner-border-radius);
}

.card-img,
.card-img-top,
.card-img-bottom {
  width: 100%; // Required because we use flexbox and this inherently applies align-self: stretch
}

.card-img,
.card-img-top {
  @include border-top-radius($card-inner-border-radius);
}

.card-img,
.card-img-bottom {
  @include border-bottom-radius($card-inner-border-radius);
}


//
// Card groups
//

.card-group {
  // The child selector allows nested `.card` within `.card-group`
  // to display properly.
  > .card {
    margin-bottom: $card-group-margin;
  }

  @include media-breakpoint-up(sm) {
    display: flex;
    flex-flow: row wrap;
    // The child selector allows nested `.card` within `.card-group`
    // to display properly.
    > .card {
      // Flexbugs #4: https://github.com/philipwalton/flexbugs#flexbug-4
      flex: 1 0 0%;
      margin-bottom: 0;

      + .card {
        margin-left: 0;
        border-left: 0;
      }

      // Handle rounded corners
      @if $enable-rounded {
        &:not(:last-child) {
          @include border-end-radius(0);

          .card-img-top,
          .card-header {
            // stylelint-disable-next-line property-disallowed-list
            border-top-right-radius: 0;
          }
          .card-img-bottom,
          .card-footer {
            // stylelint-disable-next-line property-disallowed-list
            border-bottom-right-radius: 0;
          }
        }

        &:not(:first-child) {
          @include border-start-radius(0);

          .card-img-top,
          .card-header {
            // stylelint-disable-next-line property-disallowed-list
            border-top-left-radius: 0;
          }
          .card-img-bottom,
          .card-footer {
            // stylelint-disable-next-line property-disallowed-list
            border-bottom-left-radius: 0;
          }
        }
      }
    }
  }
}
.gradient-bg-card {
  box-shadow: rgb(50 50 93 / 25%) 0px 6px 12px -2px, rgb(0 0 0 / 30%) 0px 3px 7px -3px;
}
.card-interact {

  transition: all .1s ease-in-out;
	cursor: pointer;
	&:hover {
border-color: $primary;
	}
}

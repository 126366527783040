@import 'colors.scss';
@import '@enkla-gui/styles/core.scss';

//filer som är beroende av variables.scss (finns i enkla-gui core)
@import '/src/views/company/company.scss';
@import '/src/views/core/onboard.scss';

@import '/src/views/ticket/styles.scss';
@import '/src/views/auth/auth.scss';
@import '/src/components/layout/Greeting/styles.scss';
@import '/src/components/layout/Navbar/User/styles.scss';

html,
body,
#root {
  min-height: 100vh;
  overflow-y: hidden;
  //font-family: 'Montserrat', sans-serif;
  font-family: 'Inter', sans-serif;
  background-color: #fff;
}
article.content-article {
  padding: 15px;
  position: relative;
  max-height: 100vh;
  padding-top: 0px;
}
main.content {
  display: flex;
  flex-wrap: nowrap;
  min-height: 100vh;
  background-color: $body-bg;
}
.login-content-wrapper > article.content-article {
  padding: 0;
}
.login-content-wrapper {
  overflow-y: scroll;
}
.hidden {
display: none !important;
}

// Base class
//
// Requires one of the contextual, color modifier classes for `color` and
// `background-color`.

.badge {
  display: inline-block;
  padding: $badge-padding-y $badge-padding-x;
  @include font-size($badge-font-size);
  font-weight: $badge-font-weight;
  line-height: 1;
  color: $badge-color;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  @include border-radius($badge-border-radius);
  @include gradient-bg();

  // Empty badges collapse automatically
  &:empty {
    display: none;
  }
}

// Quick fix for badges in buttons
.btn .badge {
  position: relative;
  top: -1px;
}
.bg-light {
  color: $text;
}
.badge-table-150 {
  width: 150px;
}
.badge-icon {
  margin-right: 3px;
}
.icon-badge {
  @extend .badge;
  @extend .w-100;
  @extend .bg-light;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0;
  i {
    padding: 5px 15px;
    background: darken($light, 10);
    display: flex;
    justify-content: center;
    align-items: center;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
  }
  span {
    padding: 0px 13px;
    margin: 0 auto;
  }
}
.bg-warning {
  color: darken($orange, 25) !important;
}
.bg-success {
  color: darken($green, 25) !important;
}
.bg-danger {
  color: darken($red, 35) !important;
}

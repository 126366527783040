.ticket-comments-card {
  .ticket-comments-window {
    .ticket-comment {
      position: relative;
      color: $text;
      margin: 25px;
      display: flow-root;
      border-bottom: 2px dashed #f1f1f1;
      .col-md-12 {
        padding-bottom: 5px;
        .author {
          font-weight: 600;
          b {
            margin-left: 10px;
            color: $text-muted;
            margin-left: 5px;
            font-size: 12px;
          }
        }
      }
      .btn-link {
        position: absolute;
        top: 3px;
        right: 3px;
        font-size: 16px;
      }
      .comment {
        white-space: pre-line;
      }
    }
    .ticket-system-comment {
      background: #eff2f7;
      margin: 15px;
      padding: 10px;
      border-radius: 8px;
      color: #495057;
    }
    .ticket-comment:last-child {
    }
  }
  .ticket-comments-form {
    padding: 0px 25px;
    padding-top: 30px;
    button {
      float: right;
      margin-top: 7px;
    }
  }
}

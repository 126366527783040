.component_InfoBox {
  .card-body {
    padding: 20px !important;
    .infobox-wrapper {
      display: flex;
      justify-content: space-between;
      .infobox-icon {
        margin-left: 15px;
      }
      .card-title {
        font-weight: 500;
        font-size: 14px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        &:hover {
          cursor: default;
        }
      }

      .card-subtitle {
        font-weight: 600;
        font-size: 18px;
        margin-top: 1px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        &:hover {
          cursor: default;
        }
      }

      .card-link-wrapper {
        margin-top: 10px;

        .card-link {
          margin-top: 5px;
          text-decoration: none;
          font-size: 14px;

          i {
            font-size: 12px;
          }
        }
      }

      .card-display-icon-wrapper {
        background: $enkla-secondary;

        &.success {
          background: $success;
        }

        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 45px;
        height: 45px;

        i {
          color: #fff;
          font-size: 20px;
        }
      }
    }
  }
}


.form-inline-error {
  font-size: 13px;
  color: #fff;
  background: $red;
  padding: 8px 20px;
  font-weight: 600;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  margin-top: -4px
}

.form-error {
  background: #e74c3c;
  color: #fff;
  font-weight: 700;
  font-size: 13px;
  padding: 5px 10px;
  position: absolute;
  right: -275px;
  z-index: 10000007;
  margin-top: 9px;
  border-top-right-radius: 3px;
  min-width: 292px;
  border-bottom-right-radius: 3px;
  text-align: center;
  padding-right: 45px;

  i {
    margin-left: 5px;
    position: absolute;
    right: 0px;
    top: 0px;
    background: #c0392b;
    padding: 8px;
    justify-content: center;
    align-items: center;
    display: flex;
    width: 30px;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    height: 29px;
  }

  .arrow {
    position: absolute;
    left: -15px;
    margin-top: -5px;
    width: 0;
    height: 0;
    border-top: 14.5px solid transparent;
    border-bottom: 14.5px solid transparent;
    border-right: 15px solid #e74c3c;
  }
}

th {
  width: auto;
}

th.min {
  width: 1%;
  white-space: nowrap;
}

.form-group {
  position: relative;

  label {
    font-size: 14px;
    font-weight: 500;
    padding-bottom: 2px;
  }

  .form-control,
  .form-select,
  textarea {
    font-size: 15px;
    resize: none;
    color: $text;
    border: none;
    border: 2px solid transparent;
    padding: 12px;
    transition: .1s;

    &:focus {
      color: #3d3d3d;
      outline: none;
      border-color: inherit;
      -webkit-box-shadow: none;
      box-shadow: none;
      border: 2px solid #ccc;
      transition: .1s;
    }
  }

  input[type="file"] {
    padding: 6px;
  }

  input[type=file]::file-selector-button {
    border: none;
    padding: 12px 25px;

  }
}


.form-group-disabled {

  &:hover {
    cursor: not-allowed !important;
  }

  label {
    color: #7f7e7e;
  }

  input,
  select,
  textarea {
    background-color: #f1f1f196 !important;
    color: #7f7e7e !important;
    padding-right: 33px !important;

    &:hover {
      cursor: not-allowed !important;
    }
  }

  &:after {
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    content: '\f023';
    position: absolute;
    right: 15px;
    top: 38px;
    font-size: 14px;
    color: #7f7e7e;
  }
}

.form-control-input {
  padding-top: 22px !important;
  padding-bottom: 5px !important;
}

.label-control {
  position: absolute;
  top: 6px;
  font-weight: 600 !important;
  font-size: 11px !important;
  left: 12px;
}

.input-group-text {
  margin: 2px;
  border: none;
  margin-left: -2px !important;
  padding: 10px 20px;
  background: $primary;
  color: #fff;
  text-decoration: none;
  transition: all .1s;

  &:hover {
    cursor: pointer;
    background: darken($primary, 10);
    color: #fff;
  }
}

.component_MaintenanceTasks {
  .btn-primary {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }
  .tasks-wrapper {
    margin-top: 2rem;
		.taskerino-COMPLETE {
			background-color: #e1f9dd !important;
		}
		.taskerino-FAILED{
			background-color: #e74c3c1a !important;
		}
    .taskerino-wrapper {
      background-color: #cccccc1b;
      margin-bottom: 15px;
      border-radius: 3px;
      display: flex;
      justify-content: space-between;
      align-items: baseline;
      .task-task {
        flex-grow: 1;
        padding: 10px;
      }
      button {
        margin-right: 5px;

        .fa-trash {
          color: rgba(255, 0, 0, 0.85);
        }
      }
      .taskeritask {
            padding: 15px;

    border: none;
    margin: 14px;
    border-radius: 3px;
    width: 45px;
    height: 45px;
    }
    .btn-gray {
    i {
    color: #d1d1d1 !important;
    }
    }

    }
  }
}

.component_CoolChoice {
  .cool-choice {
    height: 100%;
    padding: 20px;
    border-radius: 0.75rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background: #e0f2fe;
    border: 1px dashed darken(#e0f2fe, 5);
    &:hover {
      cursor: pointer;
      background: darken(#e0f2fe, 5);
      border: 1px dashed darken(#e0f2fe, 10);
      transition: all 0.2s;
    }
    .icon {
      color: #38bdf8;
      font-size: 35px;
      padding-right: 20px;
    }
    .text {
      display: grid;
      .title {
        color: #0369a1;
        font-weight: 500;
        font-size: 15px;
      }
      .description {
        color: $text-light;
      }
    }
  }
}

.task-wrapper {
  padding: 20px;
  padding-bottom: 0px;
  .task-list {
    list-style: none;
    position: relative;
    margin: 0;
    padding: 0px 0 0;
    color: #3c4858;
    li {
      position: relative;
      margin-bottom: 23px;
      padding-left: 55px;
      .task-icon {
        position: absolute;
        left: 10px;
        top: 5px;
        border-radius: 50%;
        padding: 2px;
        width: 12px;
        height: 12px;
        z-index: 2;
      }
      .event-buttons {
        opacity: 0.2;
        align-items: baseline;
        &:hover {
          opacity: 1;
        }
      }
      a {
        height: 25px;
      }

      &.first {
        &.last {
          &:before {
            content: '';
            position: absolute;
            top: 4px;
            bottom: 0;
            height: 90%;
            left: 15px;
            border-left: 1px solid #fff;
          }
        }
      }
    }
    &:before {
      content: '';
      position: absolute;
      top: 4px;
      bottom: 0;
      height: 90%;
      left: 15px;
      border-left: 1px solid #e0e9f1;
    }
  }
}

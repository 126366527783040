.component_NoContentBox {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100px;
  flex-grow: 1;
  .ncb-inner {
    color: $text;
    min-height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    i {
      font-size: 16px;
      margin-right: 5px;
    }
    span {
      font-size: 14px;
      font-weight: 500;
    }
  }
}

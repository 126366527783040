.react-datepicker__month-container {
  min-height: 272px;
}

.react-datepicker__close-icon {
  padding: 0 13px 0 0;

  &:after {
    background-color: transparent !important;
    color: #555 !important;
    font-size: 20px !important;
  }
}

.react-datepicker__triangle {
  left: -20px !important;
}
.component_Help {
  .card {
    box-shadow: none;
    &.temporary {
      position: relative;
      overflow: hidden;
      padding-left: 65px;
      &:before {
        font-family: 'Font Awesome 5 Free';
        font-weight: 900;
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        width: 65px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1.1rem;
        color: #fff;
        content: '\f0f3';

        opacity: 0.3;
      }
    }
  }
  .card-body {
    color: #5e6278;
    font-weight: 500;
    font-size: 15px;
    padding: 18px;
  }
  .card-warning {
    &.temporary {
      &:before {
        background: #ffc700;
      }
    }
    background: #fff8dd;
    .card-header {
      background: #fff8dd;
    }
  }
	.card-primary {
    &.temporary {
      &:before {
        background: $primary;
      }
    }
    background: $primary;
		.card-body {
			color: #fff;
		}
    .card-header {
      background: $primary;
    }
  }
  .card-danger {
    &.temporary {
      &:before {
        background: #f1416c;
      }
    }
    background: #fee2e2;
    .card-header {
      background: #fee2e2;
    }
  }
  .card-info {
    &.temporary {
      &:before {
        background: #009ef7;
      }
    }
    background-color: #dbeafe;
    .card-header {
      background: #dbeafe;
    }
  }
  .card-success {
    &.temporary {
      &:before {
        background: #50cd89;
      }
    }
    background-color: #dcfce7;
    .card-header {
      background: #dcfce7;
    }
  }
  .card-header {
    padding-bottom: 0px;
    padding-left: 18px;
    padding-top: 18px;
    span {
      color: #5e6278;
      font-weight: 700;
      font-size: 16px;
    }
    i {
      color: #5e6278;
      font-size: 16px;
      margin-right: 10px;
    }
  }
  .card-body {
    border-bottom-left-radius: 0.75rem;
    border-bottom-right-radius: 0.75rem;
  }
}

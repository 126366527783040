.component_Pipey {
	padding: 20px;
	.form-group {
		padding: 20px 0px;
	}
	.qa-wrapper {

		display: grid;
		margin-bottom: 20px;
		span:first-child {
			font-size: 17px;
			font-weight: 500;
		}
		span:last-child {
			margin-top: 4px;
			font-size: 12px;
			color: #6c757d;
		}
	}
	.qas {
		position: relative;
		overflow: auto;
		max-height: 60vh;
	}
}

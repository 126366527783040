.orgchart {
  background: #fff;
  .card-body {
    text-align: left !important;
  }
}
.orgchart-container {
  overflow: hidden !important;
  border: none;
}
.orgchart > ul > li > ul li::before {
  border-top-color: rgba($primary, 0.15);
}
.orgchart > ul > li > ul li > .oc-node::before,
.orgchart ul li .oc-node:not(:only-child)::after {
  background-color: rgba($primary, 0.15);
}
.orgchart ul li .oc-node.selected,
.orgchart ul li .oc-node:hover {
  background-color: transparent !important;
}

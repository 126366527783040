@keyframes wiggle {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(-5deg);
  }
  75% {
    transform: rotate(5deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

.component_Navbar {
  .navbar-backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
  .dropdown-divider {
    color: #a7aeb8 !important;
  }
  justify-content: space-between;

  background: $body-bg;
  display: flex !important;
  align-items: center;
  padding: 15px 0px 15px 10px;
  .navbar-brand {
    cursor: pointer;
    display: flex;
    color: $sidebar-primary;
    margin: 0;
    transition: 0.5s;
    &:hover {
      animation: wiggle 0.5s infinite;
    }
  }
  .navbar-user {
    margin-right: 15px;
    .nav-link {
      color: $sidebar-primary;
      font-size: 18px;
      font-weight: 500;
    }
  }
  .navbar-search {
    margin: 0 30px;
    flex-grow: 1;
    max-width: 600px;
    display: flex;
    position: relative;
    width: 1px;
    input {
      z-index: 1002;
      outline: none;
      background: #fff;
      width: 100%;
      border: 2px solid #eee;
      border-radius: 4px;
      box-sizing: border-box;
      padding: 12px 20px;

      font-family: inherit;
      font-size: inherit;
      font-weight: 500;
      line-height: inherit;
      &:focus {
        outline: none;
        border-color: inherit;
        -webkit-box-shadow: none;
        border-color: #888;
        transition: all 0.2s;
        box-shadow: none;
      }
      &:disabled {
        color: rgba(0, 0, 0, 0.6);
      }
    }
    button {
      position: absolute;
      right: 18px;
      border: 0;
      top: 6px;
      &:active,
      &:focus {
        border: none !important;
        outline: none !important;
      }
    }
    .navbar-search-results {
      position: absolute;
      top: -10px;
      left: -10px;
      right: -10px;
      box-shadow: none;
      .card-body {
        background: #fff;
        padding-top: 75px !important;
        z-index: 1001;
        overflow: hidden;
        border-radius: 10px;
      }
      .search-clear-results {
        z-index: 1000;
        background: rgba(0, 0, 0, 0.2);
        position: fixed;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
      }
    }
  }
  .dropdown {
    padding-right: 0px !important;
    .dropdown-toggle {
      margin-top: -5px;
      margin-bottom: -5px;
      img {
        border: 2px solid transparent;
      }
      &:hover,
      &:focus {
        img {
          border: 2px solid $gray-300;
        }
      }
    }
    .show {
      display: block !important;
    }
    > a {
      font-size: 16px;
    }
    .notifications-dropdown-menu {
			background: #fff;
      @media (max-width: 450px) {
        width: 80vw !important;
        right: -40px !important;
      }
    }
    .dropdown-menu {
      max-height: 90vh;
      overflow-y: auto;
      overflow-x: hidden;
      display: none;
      right: 3px;
      top: 59px;
      padding: 0px;
      position: absolute;
      .dropdown-item,
      button {
        color: $text !important;
        padding: 15px;
        i {
          margin-right: 5px;
        }
      }

      .dropdown-divider {
        margin: 0 !important;
      }
    }

    li {
      cursor: pointer;
    }
  }
}

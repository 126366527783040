.component_Greeting {
  h2 {
    font-size: 1.75rem;
    letter-spacing: -0.025em !important;
    font-weight: 600;
    line-height: 1.3;
    margin-bottom: 0;
    margin-top: 0;
  }
}
